///
// Skin: Default
// Author: Tian Qi
// Email: kitian616@outlook.com
///

// main colors
$main-color-1:     #1779ba;
$text-color-1:     #fff;

$main-color-2:     #1727ba;
$text-color-2:     #fff;

$main-color-3:     #f2f2f2;
$text-color-3:     #333;

$main-color-theme-light: rgba(#000, .9);
$main-color-theme-dark:  rgba(#fff, .9);

// page background
$background-color: #fff;

// text colors
$text-color-theme-light-d: #000;
$text-color-theme-light:   #222;
$text-color-theme-light-l: #888;

$text-color-theme-dark-d:  #fff;
$text-color-theme-dark:    rgba(#fff, .95);
$text-color-theme-dark-l:  rgba(#fff, .85);

$text-color-d:     $text-color-theme-light-d;
$text-color:       $text-color-theme-light;
$text-color-l:     $text-color-theme-light-l;

$text-background-color: rgba(#000, .05);

// header and footer colors
$header-text-color: $text-color-3;
$header-background: $main-color-3;

$footer-text-color: $text-color-3;
$footer-background: $main-color-3;

// border and shadow colors
$border-color:     mix(#000, $background-color, 20%);
$border-color-l:   mix(#000, $background-color, 10%);
$decorate-color:   rgba(#000, .1);
$mask-color:       rgba(#000, .9);
$select-color:     rgba($main-color-1, .5);

// function colors
$green:           #52c41a;
$blue:            #1890ff;
$yellow:          #fa8c16;
$red:             #f5222d;
$text-color-function: #fff;

// logo colors
$mail-color:       #0072c5;
$facebook-color:   #4267b2;
$twitter-color:    #1da1f2;
$weibo-color:      #e6162d;
$google-plus-color:#ea4335;
$telegram-color:   #32afed;
$medium-color:     #000;
$zhihu-color:      #0084ff;
$douban-color:     #42bd56;
$linkedin-color:   #1074af;
$github-color:     #000;
$npm-color:        #fff;

// highlight colors
@import "skins/highlight/tomorrow";
