/* start custom scss snippet */

#site-title::before {
  background-image: url(/assets/logo.png);
  background-size: 30px 30px;
  vertical-align: middle;
  display: inline-block;
  width: 30px;
  height: 30px;
  content: '';
  margin-right: .75em;
}

/* end custom scss snippet */
