.layout--404 {
  text-align: center;
  .sign {
    display: table;
    margin: map-get($spacers, 4) auto;
    margin-top: map-get($spacers, 5);
    h1 {
      font-size: map-get($base, font-size-xl) * 4;
      line-height: 1;
    }
    p {
      font-size: map-get($base, font-size-xl) * 1.2;
    }
  }
}
